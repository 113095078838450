import BusySpinner from 'common/indicators/BusySpinner'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTION_STATE_COMPLETED,
  MANUAL_SUBMITTABLE_INTERACTIVES,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import { componentShape } from 'core/shapes'
import { isDefined, matches } from 'fp/utils'
import useContent from 'hooks/useContent'
import useCurrentUser from 'hooks/useCurrentUser'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import useInteraction from 'hooks/useInteraction'
import { BUSY } from 'hooks/useReduxCallback'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { isPastMaxSubmitDate } from 'hss/utils'
import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { getContextualAssignment } from 'selectors/assignments'
import { getPeerInteractionsForAssignmentContent } from 'selectors/interactions'
import { getLocalSetting } from 'selectors/localSettings'
import { getUserAssignmentById } from 'selectors/userAssignments'
import { isStudent } from 'selectors/users'
import { interactiveGradingContext } from './InteractiveGradingProvider'

export const interactiveContext = createContext()

const InteractiveProvider = props => {
  const {
    Renderer,
    allowedInteractives,
    children,
    childrenMetadata = [],
    contentBlockVariant,
    contentid: contentId,
    hideStudentPicker,
    initialTestingState,
    previewing = false,
    reaction,
    renderProps,
    reviewed = false,
  } = props

  const [waitingToSubmit, setWaitingToSubmit] = useState(false)
  const [submissionArguments, setSubmissionArguments] = useState()

  const { attachedScaffolds: availableScaffolds = [] } =
    childrenMetadata.find(matches('id', contentId)) || {}

  const contentType =
    initialTestingState?.interactive?.contentType || CONTENT_TYPE_INTERACTIVE

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  const interactive =
    useContent({
      contentId,
      contentType,
    }) || initialTestingState?.interactive
  const [submitEnabled, setSubmitEnabled] = useState(true)

  /**
   * `boosted` is a way for an interactive to alert the outer containers when it's
   * doing something internally that will affect its position within the layout.
   *
   * An example is the Image interactive, which does not use the standard 'expanded'
   * flag to enter full screen mode, but instead has its own internal mechanism.
   * It will set boosted to true when that internal full-screen mode is active,
   * so the outer container knows to dismantle the stacking context that would
   * otherwise cause z-indexing to not give the desired outcome.
   *
   * Basically it's for non-standard full-screen rendering, or for times when an
   * interactive needs to be able to break out of the normal layout flow.
   */
  const [boosted, setBoosted] = useState(false)

  const assignment = useSelector(getContextualAssignment) || {}
  const assignmentId = initialTestingState?.assignmentId || assignment.id

  const {
    interaction: myInteraction,
    markComplete: markCompletePrime,
    markIncomplete,
    saveInteraction,
    transportStatus,
  } = useInteraction({ contentId, contentType })

  const peerInteractions = useSelector(
    getPeerInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  const interaction = presenterModeEnabled
    ? null
    : initialTestingState?.interaction ||
      reaction?.peerInteraction ||
      myInteraction

  const { userAssignmentId } = interaction || {}
  const { submittedDate } =
    useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const assignmentSubmitted = isDefined(submittedDate)

  const {
    user: { proficiencyId } = {},
  } = useCurrentUser() || {}
  const showAllProficiencies = !useSelector(isStudent)

  const attachedScaffolds = useMemo(
    () =>
      availableScaffolds
        .filter(
          ({ proficiencyIds }) =>
            showAllProficiencies || proficiencyIds.includes(proficiencyId),
        )
        // reversed order so they sort 'beginning' - 'advanced'
        .reverse(),
    [availableScaffolds, proficiencyId, showAllProficiencies],
  )

  const { contentWrappingAllowed = false } =
    useContext(contentViewerContext) || {}

  const isInGradingContext = isDefined(useContext(interactiveGradingContext))
  const isInAssignmentContext = !!useSelector(getContextualAssignment)
  const isGrading = isInGradingContext && isInAssignmentContext
  const currentUserIsStudent = useSelector(isStudent)

  const onInteract = useCallback(
    (...args) => {
      if (reaction) return // cannot save peer interactions
      if (!(currentUserIsStudent || isGrading)) return // teachers can only save when grading, and admins can never save
      if (assignmentSubmitted && !isGrading) return // cannot save to submitted assignments unless a teacher is grading
      saveInteraction(...args)
    },
    [
      assignmentSubmitted,
      currentUserIsStudent,
      isGrading,
      reaction,
      saveInteraction,
    ],
  )

  const completed = interaction?.state === INTERACTION_STATE_COMPLETED
  const submittableInteractive = MANUAL_SUBMITTABLE_INTERACTIVES.includes(
    interactive?.contentSubType,
  )

  const submittable =
    submittableInteractive &&
    (!(completed || assignmentSubmitted) || presenterModeEnabled)

  const gradingEnabled =
    completed || assignmentSubmitted || isPastMaxSubmitDate(assignment)

  // MARK: markComplete
  const markComplete = useCallback(
    opts => {
      if (!assignmentSubmitted) {
        setSubmissionArguments(opts)
        setWaitingToSubmit(true)
      }
    },
    [assignmentSubmitted],
  )

  useEffect(() => {
    if (waitingToSubmit && transportStatus !== BUSY) {
      setWaitingToSubmit(false)

      markCompletePrime({
        ...submissionArguments,
        suppressAlert: !submittableInteractive,
      })
    }
  }, [
    markCompletePrime,
    submissionArguments,
    submittableInteractive,
    transportStatus,
    waitingToSubmit,
  ])

  /**
   * We'd be extracting some items often enough for them to benefit from being
   * separate items.
   *
   * Things like: `completed`, `data` and `contentId`.
   *
   * Adding these separately to this context is purely for convenience.  They could
   * all be derived from `interactive` or `interaction`.
   */
  const value = useDeepCompareMemo(
    () => ({
      allowedInteractives,
      assignmentId,
      attachedScaffolds,
      boosted,
      busy: transportStatus === BUSY,
      children: interactive?.children,
      completed,
      contentBlockVariant,
      contentId: interactive?.id,
      contentWrappingAllowed,
      gradingEnabled,
      hideStudentPicker,
      interaction,
      interactionData: interaction?.interactionData || {},
      interactive,
      interactiveData: interactive?.data || {},
      isGrading,
      markComplete,
      markIncomplete,
      onInteract,
      peerInteractions,
      previewing,
      Renderer,
      renderProps,
      reviewed,
      scoreData: interaction?.scoreData,
      setBoosted,
      setSubmitEnabled,
      submitEnabled,
      submittable,
      submittableInteractive,
      submittedDate,
      transportStatus,
      uploadsMap: interactive?.uploadsMap,
      ...initialTestingState,
    }),
    [
      allowedInteractives,
      assignmentId,
      attachedScaffolds,
      boosted,
      completed,
      contentBlockVariant,
      contentWrappingAllowed,
      hideStudentPicker,
      interaction,
      interactive,
      isGrading,
      markComplete,
      markIncomplete,
      onInteract,
      peerInteractions,
      previewing,
      renderProps,
      Renderer,
      reviewed,
      submittable,
      submitEnabled,
      submittedDate,
      transportStatus,
      initialTestingState,
    ],
  )

  return (
    <interactiveContext.Provider value={value}>
      {interactive ? children : <BusySpinner />}
    </interactiveContext.Provider>
  )
}

const InteractiveProviderPropTypes = {
  contentBlockVariant: PropTypes.string,
  contentid: PropTypes.string,
  initialTestingState: PropTypes.object,
  previewing: PropTypes.bool,
  Renderer: componentShape.isRequired,
  reviewed: PropTypes.bool,
}

InteractiveProvider.propTypes = {
  children: componentShape.isRequired,
  ...InteractiveProviderPropTypes,
}

export default InteractiveProvider

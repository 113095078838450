import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import LinkButton from 'common/navigation/links/LinkButton'
import CardTitle from 'common/text/CardTitle'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import Html from 'common/text/Html'
import {
  ABILITY_ASSIGNMENT_CREATION,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_VOCABULARY,
} from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { labels } from 'hss/ContentBuilder/consts'
import CardFooter from 'hss/views/Card/CardFooter'
import ChapterAssignmentLink from 'hss/views/Curriculum/Units/Unit/ChapterCard/ChapterAssignmentLink'
import {
  NON_ASSIGNABLE_CONTENT_TYPES,
  cardContentRendererShape,
} from 'hss/views/Search/consts'
import { useSelector } from 'react-redux'
import { getTeacherAssignmentCreateRoute } from 'routing/consts'
import { getContentViewerUrl } from 'selectors/contentViewer'
import CardActionMenu from '../Search/CardActionMenu'

const buttonLabels = {
  [CONTENT_TYPE_VOCABULARY]: 'Details',
}

const Bullet = () => <span>&nbsp; &bull; &nbsp;</span>

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: prolly fine
const SearchItemRenderer = props => {
  const { content, contentCreator, handlePreview, viewMode } = props

  const {
    assetCode,
    contentSubType,
    contentType,
    data,
    id: contentId,
    name,
  } = content

  const { assetDate, creator, creatorType, genre } = data

  const isList = viewMode === 'list'
  const headlineVariant = isList ? 'h4' : 'grid-search-heading'
  const assignable =
    useAbilityCheck(ABILITY_ASSIGNMENT_CREATION) &&
    !NON_ASSIGNABLE_CONTENT_TYPES.includes(contentType)
  const isInteractiveOrRubric = [
    CONTENT_TYPE_INTERACTIVE,
    CONTENT_TYPE_RUBRIC,
  ].includes(contentType)
  const isSource = contentType === CONTENT_TYPE_SOURCE

  const viewUri = useSelector(getContentViewerUrl({ contentId, contentType }))
  const description = data.description || data.caption || data.definition

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'space-between',
        height: '100%',
      }}>
      <div>
        <HeadlineLevelOffset>
          <HeadlineLevelOffset>
            <CardHeader
              subheader={
                Boolean(isSource) && (
                  <Typography variant="feature-paragraph">
                    {Boolean(creator) && <span>{creator}</span>}
                    {Boolean(creator && assetDate) && <Bullet />}
                    {Boolean(assetDate) && <span>{assetDate}</span>}
                  </Typography>
                )
              }
              title={
                <CardTitle
                  eyebrow={labels[contentSubType] || labels[contentType]}
                  headlineVariant={headlineVariant}
                  title={name || assetCode}
                />
              }
            />
          </HeadlineLevelOffset>
        </HeadlineLevelOffset>

        <CardContent>
          {Boolean(isSource) && (
            <Typography variant="paragraph">
              {Boolean(genre) && <span>{genre}</span>}
              {Boolean(genre && creatorType) && <Bullet />}
              {Boolean(creatorType) && <span>{creatorType}</span>}
            </Typography>
          )}
          <Html body={description} />
        </CardContent>
      </div>

      <CardActions>
        <Stack
          direction={isList ? 'row' : { md: 'column', lg: 'row' }}
          gap={2}
          justifyContent={isList ? 'space-after' : 'center'}
          padding={2}
          paddingLeft={isList ? 0 : 2}
          paddingTop={0}
          width="100%">
          {!isInteractiveOrRubric && (
            <LinkButton
              color="secondary"
              to={viewUri}
              variant="secondary">
              View {buttonLabels[contentType] || labels[contentType]}
            </LinkButton>
          )}

          {assignable ? (
            <LinkButton
              color="secondary"
              to={getTeacherAssignmentCreateRoute({
                contentType,
                id: contentId,
              })}
              variant="primary">
              Assign
            </LinkButton>
          ) : isInteractiveOrRubric ? (
            <Button onClick={handlePreview}>Preview</Button>
          ) : null}

          {Boolean(contentCreator) && (
            <CardActionMenu
              {...{
                contentId,
                contentSubType,
                contentType,
              }}
            />
          )}
        </Stack>
      </CardActions>

      {Boolean(assignable) && (
        <CardFooter>
          <ChapterAssignmentLink content={content} />
        </CardFooter>
      )}
    </Stack>
  )
}

SearchItemRenderer.propTypes = cardContentRendererShape

export default SearchItemRenderer

import Container from '@mui/material/Container'
import BusySpinner from 'common/indicators/BusySpinner'
import Filters from 'hss/views/Search/Filters'
import ResultsHeader, {
  defaultRenderHeadline,
} from 'hss/views/Search/Results/ResultsHeader'
import { searchContext } from 'hss/views/Search/SearchProvider'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import Results from './Results'
import TableResults from './TableResults'

const CommonCardSearch = props => {
  const {
    children,
    headlineProps,
    renderActions,
    renderAdvancedFilter,
    renderHeadline = defaultRenderHeadline,
    renderPrimaryFilter,
    renderSecondaryFilter,
    suppressEmptyResult,
  } = props

  const { dataset, viewMode } = useContext(searchContext)

  // console.log('CommonCardSearch', { dataset, viewMode })

  return dataset ? (
    <Container>
      <ResultsHeader
        {...{
          renderActions,
          renderHeadline,
          headlineProps,
        }}>
        <Filters
          {...{
            renderAdvancedFilter,
            renderPrimaryFilter,
            renderSecondaryFilter,
            suppressEmptyResult,
          }}
        />
      </ResultsHeader>

      {viewMode === 'table' ? <TableResults /> : <Results />}

      {children}
    </Container>
  ) : (
    <BusySpinner />
  )
}

CommonCardSearch.propTypes = {
  children: PropTypes.element,
  headlineProps: PropTypes.object,
  renderActions: PropTypes.func,
  renderAdvancedFilter: PropTypes.func,
  renderHeadline: PropTypes.func,
  renderPrimaryFilter: PropTypes.func,
  renderSecondaryFilter: PropTypes.func,
  suppressEmptyResult: PropTypes.bool,
}

export default CommonCardSearch

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTreeItem } from '@mui/x-tree-view'
import cl from 'classnames'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

// TODO: Make sure this is screen reader friendly and follows ARIA guidelines.
// Spoiler alert: It doesn't yet. See also this example as a model to follow.
// https://www.w3.org/WAI/ARIA/apg/example-index/treeview/treeview-1/treeview-1a.html

// Almost an exact copy of the example from the MUI docs,
// this component allows you to expand an item without selecting it.
// (https://mui.com/material-ui/react-tree-view/#contentcomponent-prop)
const TreeContentComponent = forwardRef((props, ref) => {
  const {
    className,
    classes,
    displayIcon,
    expansionIcon,
    icon: iconProp,
    label,
    nodeId,
    notSelectedIcon,
    selectedIcon,
  } = props

  const {
    disabled,
    expanded,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
    selected,
  } = useTreeItem(nodeId)

  const icon = iconProp || expansionIcon || displayIcon
  const SelectionIcon = selected ? selectedIcon : notSelectedIcon

  return (
    <div
      className={cl(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={preventSelection}
      ref={ref}>
      <Stack
        alignItems="center"
        direction="row"
        ml={1}>
        {Boolean(SelectionIcon) && (
          <SelectionIcon
            className="selection-icon"
            height={24}
            onClick={handleSelection}
            style={{ marginRight: 12 }}
            width={24}
          />
        )}
        <Typography
          className={classes.label}
          component="div"
          onClick={handleExpansion}>
          {label}
        </Typography>
      </Stack>
      {}
      <div
        className={classes.iconContainer}
        onClick={handleExpansion}>
        {icon}
      </div>
    </div>
  )
})

TreeContentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  displayIcon: PropTypes.node,
  expansionIcon: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.node.isRequired,
  nodeId: PropTypes.string.isRequired,
  notSelectedIcon: componentShape,
  selectedIcon: componentShape,
}

export default TreeContentComponent

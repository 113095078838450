import {
  CONTENT_TYPE_RUBRIC,
  INTERACTIVE_TYPE_IMAGE,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import DecorativeImage from 'hss/sections/contentBlocks/interactives/Image/DecorativeImage'
import { Fragment, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'
import { isStudent } from 'selectors/users'
import { interactiveContext } from './InteractiveProvider'
import RubricFeedback from './RubricFeedback'
import SubmissionWrapper from './SubmissionWrapper'
import { getRegisteredInteractives } from './utils'

const registeredInteractives = getRegisteredInteractives()

const InteractiveRenderer = () => {
  const {
    contentBlockVariant,
    interactive: { contentSubType, contentType },
  } = useContext(interactiveContext)

  const InteractiveComponent =
    contentSubType === INTERACTIVE_TYPE_IMAGE &&
    contentBlockVariant === 'chapter-recap'
      ? DecorativeImage
      : registeredInteractives[
          contentType === CONTENT_TYPE_RUBRIC ? contentType : contentSubType
        ]

  if (!InteractiveComponent) {
    throw new Error(`
        !!!!!! Invariant state detected !!!!!!

        A content block references an interactive of type "${contentSubType}"
        which is not a known, registered interactive type.

        Interactive types must be one of:

        ${Object.keys(registeredInteractives).join(', ')}
      `)
  }

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )
  const userIsStudent = useSelector(isStudent)
  const MaybeSubmissionWrapper =
    userIsStudent || presenterModeEnabled ? SubmissionWrapper : Fragment

  return (
    <MaybeSubmissionWrapper>
      <InteractiveComponent />
      <RubricFeedback />
    </MaybeSubmissionWrapper>
  )
}

export default InteractiveRenderer

import { FEATURE_FLAG_ADVANCED_FILTERS } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import CommonCardSearch from 'hss/views/Search/CommonCardSearch'
import AdvancedFilters from 'hss/views/Search/Filters/AdvancedFilters'
import ContentSubTypeFilter from 'hss/views/Search/Filters/Controls/ContentSubTypeFilter'
import ContentTypeFilter from 'hss/views/Search/Filters/Controls/ContentTypeFilter'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { useContext } from 'react'
import AddToLibrary from '../AddToLibrary'
import Preview from './Preview'

const Search = () => {
  const { contentSubTypes } = useContext(searchContext)
  const hasAdvFilters = useAbilityCheck(FEATURE_FLAG_ADVANCED_FILTERS)

  return (
    <CommonCardSearch
      renderActions={() => <AddToLibrary />}
      renderAdvancedFilter={
        hasAdvFilters ? () => <AdvancedFilters /> : undefined
      }
      renderPrimaryFilter={() => <ContentTypeFilter />}
      renderSecondaryFilter={
        contentSubTypes.length > 0 ? () => <ContentSubTypeFilter /> : undefined
      }>
      <Preview />
    </CommonCardSearch>
  )
}

export default Search

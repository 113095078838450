import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import cl from 'classnames'
import { ButtonPlayer } from 'common/avclub/audio/AudioPlayer'
import Centered from 'common/layout/Centered'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import Html from 'common/text/Html'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VIDEO,
  TOGGLE_STATE_PRESENTER_MODE,
  isEchoFeature,
} from 'core/consts'
import { includes } from 'fp/arrays'
import { deepMerge, get } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { not } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import {
  SCORING_MODE_NONE,
  displayConfig as allConfigs,
} from 'hss/ContentBuilder/consts'
import { ContentVocabulary } from 'hss/ContentViewer/Chapter/Subsection/Footnotes/VocabularyForType'
import { produce } from 'immer'
import PropTypes from 'prop-types'
import { Fragment, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'
import { isStudent } from 'selectors/users'
import { importantRem, rem } from 'styling/theming/base/mixins'
import ExpanderBackdrop from './ExpanderBackdrop'
import Header from './Header'
import { interactiveContext } from './InteractiveProvider'
import Standards from './Preamble/Standards'
import ScaffoldsWrapper from './Scaffolds/ScaffoldsWrapper'
import StudentAnswerFeedback from './StudentAnswerFeedback'

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
const InteractiveContainer = ({ children }) => {
  const {
    attachedScaffolds,
    boosted,
    interactive,
    interactive: {
      contentSubType,
      id,
      scoring: { mode: scoringMode },
    },
    interactiveData: { float, frameless, studentInstructions },
    previewing,
    uploadsMap: { studentInstructionsAudio },
  } = useContext(interactiveContext)

  const maxScore =
    get('scoring.maxScoreOverride')(interactive) ||
    get('scoring.maxScore')(interactive)

  const [expanded, toggleExpanded] = useToggleState(false)

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  const MaybeScaffoldsWrapper = presenterModeEnabled
    ? Fragment
    : ScaffoldsWrapper
  const scaffoldsWrapperProps = presenterModeEnabled
    ? {}
    : { attachedScaffolds }

  const { down } = useContainerQuery()
  const isSmall = down('md')

  const hasStandardsOrVocab = not(
    includes(contentSubType)([
      INTERACTIVE_TYPE_ANIMATOR,
      INTERACTIVE_TYPE_FLIPBOOK,
    ]),
  )

  const blockPartiallyContained = ![INTERACTIVE_TYPE_IMAGE].includes(
    contentSubType,
  )
  const isAnimator = contentSubType === INTERACTIVE_TYPE_ANIMATOR
  const isEcho = isEchoFeature(contentSubType)
  const isFlipbook = contentSubType === INTERACTIVE_TYPE_FLIPBOOK
  const isVideo = contentSubType === INTERACTIVE_TYPE_VIDEO
  const showStudentInstructions = [
    INTERACTIVE_TYPE_AUDIO,
    INTERACTIVE_TYPE_CHART,
    INTERACTIVE_TYPE_FLIPBOOK,
    INTERACTIVE_TYPE_TIMELINE,
  ].includes(contentSubType)
  const showAsFrameless = frameless || isFlipbook

  let displayConfig = useMemo(
    () => deepMerge(allConfigs.defaults, allConfigs[contentSubType]),
    [contentSubType],
  )

  // The Image and Timeline interactives need an extra left margin in order for the heading to line up correctly
  if (!blockPartiallyContained) {
    displayConfig = produce(displayConfig, draft => {
      draft.preambleSx = {
        ...draft.preambleSx,
        paddingLeft: rem(4),
      }
    })
  }

  const containerClassName = useMemo(
    () =>
      cl({
        expandable: true,
        expanded,
      }),
    [expanded],
  )

  const contentClassName = useMemo(
    () =>
      cl({
        boosted,
        interactive: true,
        [`interactive-${contentSubType}`]: true,
      }),
    [boosted, contentSubType],
  )

  const isCurrentUserStudent = useSelector(isStudent)

  if (displayConfig.inline) return children

  return (
    <div
      className={cl({
        'block-full-width': true,
        'block-partially-contained': blockPartiallyContained,
        [`float-${float}`]: !!float,
      })}>
      <div
        data-contentid={id}
        data-contentsubtype={contentSubType}
        data-contenttype={CONTENT_TYPE_INTERACTIVE}>
        <HeadlineLevelOffset>
          {/**
           * The pattern here might seem odd, but it's like this in order to preserve
           * user interactions.
           *
           * Using a <Portal /> here, or placing the content within a <Backdrop />
           * would yield a similar effect, however the interactive would go through
           * an unmount/remount cycle, losing any internal state. Same would go for
           * a fullscreen dialog.
           */}
          <ExpanderBackdrop
            expanded={expanded}
            onClick={toggleExpanded}
          />

          <div className={containerClassName}>
            {!blockPartiallyContained || isVideo || isAnimator ? null : (
              <Header {...{ displayConfig, expanded, toggleExpanded }} />
            )}

            {!presenterModeEnabled && <StudentAnswerFeedback />}

            <MaybeScaffoldsWrapper {...scaffoldsWrapperProps}>
              <Box
                className={contentClassName}
                sx={{
                  ...displayConfig.contentSx,
                  ...(expanded ? displayConfig.expandedSx : null),
                  ...(showAsFrameless
                    ? {
                        bgcolor: 'background.default',
                        marginTop:
                          previewing || expanded || isAnimator
                            ? 0
                            : displayConfig.contentSx.marginTop,
                        ...displayConfig.framelessContentSx,
                      }
                    : {}),
                }}>
                {Boolean(
                  !isEcho &&
                    (!frameless || showStudentInstructions) &&
                    !isEmptyString(studentInstructions),
                ) && (
                  <Centered pb={2}>
                    <Typography
                      sx={{ '> p': { marginBottom: 0, textAlign: 'center' } }}
                      variant="feature-paragraph">
                      <Html body={studentInstructions} />
                      {Boolean(studentInstructionsAudio) && (
                        <ButtonPlayer url={studentInstructionsAudio.url} />
                      )}
                    </Typography>
                  </Centered>
                )}

                {children}
              </Box>
            </MaybeScaffoldsWrapper>
          </div>
        </HeadlineLevelOffset>
      </div>
      {Boolean(hasStandardsOrVocab || maxScore) && (
        <Grid
          item
          xs={isSmall ? 12 : 6}>
          <Typography
            component="div"
            sx={{
              textAlign: isSmall ? 'left' : 'right',
              paddingBottom: rem(3),
              marginTop: rem(-1),
              li: {
                fontSize: importantRem(1.2),
              },
            }}
            variant="small">
            <Stack gap={0.5}>
              {Boolean(hasStandardsOrVocab) && (
                <>
                  {Boolean(!isCurrentUserStudent) && (
                    <Standards config={interactive} />
                  )}
                  <ContentVocabulary
                    content={interactive}
                    variant="self"
                  />
                </>
              )}
              {Boolean(scoringMode !== SCORING_MODE_NONE && maxScore) && (
                <div>
                  <Typography variant="small-semibold">
                    Maximum Points:
                  </Typography>{' '}
                  {maxScore}
                </div>
              )}
            </Stack>
          </Typography>
        </Grid>
      )}
    </div>
  )
}

InteractiveContainer.propTypes = {
  children: PropTypes.element.isRequired,
}

export default InteractiveContainer

import Centered from 'common/layout/Centered'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { shuffle } from 'fp/arrays'
import { useChapterVocabPhrases } from 'hooks/useChapterVocabPhrases'
import useEffectOnce from 'hooks/useEffectOnce'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import RankingList from './RankingList'

const Content = () => {
  const {
    completed,
    contentId,
    interactionData: { value },
    interactiveData: { items: itemsFromInteractive, variant },
    isGrading,
    onInteract,
    submittable,
  } = useContext(interactiveContext)

  const contentVocabPhrases = useChapterVocabPhrases({
    contentId,
    contentType: CONTENT_TYPE_INTERACTIVE,
  })
  const items = useMemo(
    () =>
      variant === interactiveVariants.EVALUATION_RANKING_VOCAB_FAMILIARITY
        ? contentVocabPhrases.map(({ id, name }) => ({
            id,
            label: name,
          }))
        : itemsFromInteractive,
    [contentVocabPhrases, itemsFromInteractive, variant],
  )

  const shuffledItems = useRef(shuffle(items))

  const [localItems, setLocalItems] = useState(value || [])

  const showAnswerKey = useIsInAnswerKeyContext()

  useEffectOnce(() => {
    if (showAnswerKey) {
      setLocalItems(itemsFromInteractive)
    } else if (!(value?.length || completed || isGrading)) {
      setLocalItems(shuffledItems.current)
      onInteract({ value: shuffledItems.current })
    }
  }, [
    completed,
    isGrading,
    itemsFromInteractive,
    onInteract,
    showAnswerKey,
    value,
  ])

  useEffect(() => {
    if (value?.length && isGrading) {
      setLocalItems(value)
    }
  }, [isGrading, value])

  const handleChange = useCallback(
    ({ target }) => {
      setLocalItems(target.value)
      onInteract(target)
    },
    [onInteract],
  )

  return isGrading && !value ? (
    <Centered>(no response)</Centered>
  ) : (
    <RankingList
      disabled={isGrading || !submittable || showAnswerKey}
      items={localItems}
      length={items?.length}
      name="items"
      onChange={handleChange}
    />
  )
}

export default Content

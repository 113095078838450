import { useTheme } from '@mui/material/styles'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import {
  ABILITY_CONTENT_EDITING,
  ABILITY_STUDENT_INTERFACE,
  ABILITY_TEACHER_INTERFACE,
  CONTENT_TYPE_SUBSECTION,
  FEATURE_FLAG_SPANISH_CONTENT,
  INTERACTION_TYPE_ANNOTATION,
  LEVELED_TEXT_BODY_FIELD_NAME,
  PROFICIENCY_GROUPING_BELOW_LEVEL,
  PROFICIENCY_GROUPING_ENGLISH_LEARNER,
  SPANISH_BODY_FIELD_NAME,
  TOGGLE_STATE_PRESENTER_MODE,
  TOGGLE_STATE_TOOLBAR_ANNOTATIONS,
  TOGGLE_STATE_TOOLBAR_LEVELED_CONTENT,
  TOGGLE_STATE_TOOLBAR_SPANISH_CONTENT,
  TOGGLE_STATE_TOOLBAR_TE_CONTENT,
  USE_LEVELED_TEXT_BY_PROFICIENCY,
  USE_LEVELED_TEXT_FOR_ALL_STUDENTS,
} from 'core/consts'
import { contentTypeShape } from 'core/shapes'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import useAbilityChecker from 'hooks/useAbilityChecker'
import useContent from 'hooks/useContent'
import useCurrentUser from 'hooks/useCurrentUser'
import useReduxPromise from 'hooks/useReduxPromise'
import useToggleState from 'hooks/useToggleState'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import {
  assembleSideBySideSeAndTeBlocks,
  markVocabPhrasesInBlocks,
} from 'projections/content'
import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import Alerts from 'routing/shells/TocShell/Alerts'
import { getContextualAssignment } from 'selectors/assignments'
import { hasAlternateBodiesForSelfOrDescendants } from 'selectors/content'
import {
  childBlocksHaveTeContent,
  getAnyAncestorIsTe,
  getBlocksForSubsectionOrEcho,
  getSubsectionOrEchoAccentColor,
  getViewerTopLevelContent,
} from 'selectors/contentViewer'
import { getAnnotationsForContent } from 'selectors/interactions'
import { getUserAssignment } from 'selectors/userAssignments'
import { getCurrentUserProficiency } from 'selectors/users'
import { getAggregatedVocabFromContentChildren } from 'selectors/vocabulary'
import AccentColorProvider from 'styling/theming/AccentColorProvider'

const contentViewerContext = createContext()

const leveledProficiencyGroupings = [
  PROFICIENCY_GROUPING_BELOW_LEVEL,
  PROFICIENCY_GROUPING_ENGLISH_LEARNER,
]

const useCurrentUserShouldSeeLeveledContent = () => {
  const { grouping: userProficiencyGrouping } =
    useSelector(getCurrentUserProficiency) || {}

  const { leveledNarrativeTextSetting: settingFromAssignmentEditContext } =
    useContext(assignmentEditContext) || {}
  const settingFromExistingAssignment = compose(
    get('data.settings.leveledNarrativeText'),
    fallbackTo({}),
    useSelector,
  )(getContextualAssignment)
  const leveledTextAssignmentSetting =
    settingFromAssignmentEditContext || settingFromExistingAssignment

  return (
    leveledTextAssignmentSetting === USE_LEVELED_TEXT_FOR_ALL_STUDENTS ||
    (leveledTextAssignmentSetting === USE_LEVELED_TEXT_BY_PROFICIENCY &&
      leveledProficiencyGroupings.includes(userProficiencyGrouping))
  )
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: refactor
const ContentViewerProvider = props => {
  const {
    children,
    contentType = CONTENT_TYPE_SUBSECTION,
    initialTestState,
    paramName = 'subsectionId',
  } = props
  const has = useAbilityChecker()
  const {
    palette: { sectionAccents },
  } = useTheme()
  const sideBySideTeAllowed = useContainerQuery().up('md')
  const {
    user: { id: userId, proficiencyId },
  } = useCurrentUser()
  const [videosInTheaterMode, setVideosInTheaterMode] = useState(false)
  const [retrievedInteractions, setRetrievedInteractions] = useState(false)
  const [
    studentViewingTeacherAnnotations,
    setStudentViewingTeacherAnnotations,
  ] = useState(false)
  const currentUserIsTeacher = has(ABILITY_TEACHER_INTERFACE)
  const currentUserIsStudent = has(ABILITY_STUDENT_INTERFACE)

  const content = useContent({ contentType, paramName })

  const {
    academicVocabPhrases = [],
    contentVocabPhrases = [],
    footnoteVocabPhrases = [],
  } = useSelector(getAggregatedVocabFromContentChildren({ content }))

  const vocabPhrases = [
    academicVocabPhrases,
    contentVocabPhrases,
    footnoteVocabPhrases,
  ].flat()

  const blocks = useSelector(
    getBlocksForSubsectionOrEcho(content, {
      expandInteractives: true,
      currentUserIsStudent,
      userProficiencyId: proficiencyId,
    }),
  )

  const ancestorHasTe = useSelector(getAnyAncestorIsTe({ content }))

  const { id: contentId } = content || {}

  const chapter = useSelector(getViewerTopLevelContent) || {}

  const { id: userAssignmentId } = useSelector(getUserAssignment) || {}
  const { data: assignmentData, id: assignmentId } =
    useSelector(getContextualAssignment) || {}
  const { settings: assignmentSettings } = assignmentData || {}

  const inCurriculum = !(userAssignmentId || assignmentId)
  const getOwnInteractions = inCurriculum || currentUserIsStudent

  const fetchInteractions = useReduxPromise(actionTypes.INTERACTION_FETCH_LIST)

  useEffect(() => {
    // Fetching all child interactions up front.
    // Individual interactives can assume they've been fetched.

    // always fetch teacher annotations, since they are viewable by both teachers and students
    fetchInteractions({
      contentId: chapter.id,
      interactionType: INTERACTION_TYPE_ANNOTATION,
      sharedAssignmentId: assignmentId,
    })

    // student annotations
    if (currentUserIsStudent) {
      fetchInteractions({
        assignmentId,
        contentId: chapter.id,
        userAssignmentId,
        userId: getOwnInteractions ? userId : null,
      }).finally(() => {
        setRetrievedInteractions(true)
      })
    } else {
      setRetrievedInteractions(true)
    }
  }, [
    assignmentId,
    chapter.id,
    currentUserIsStudent,
    fetchInteractions,
    getOwnInteractions,
    userAssignmentId,
    userId,
  ])

  const [annotationsOn, toggleAnnotations] = useToggleState(
    true,
    TOGGLE_STATE_TOOLBAR_ANNOTATIONS,
  )
  const [audioAvailable, setAudioAvailable] = useState(false)
  const [audioTrackEnabled, toggleAudioTrack] = useToggleState(false)
  const [displayingLeveledContent, toggleLeveledContent] = useToggleState(
    false,
    TOGGLE_STATE_TOOLBAR_LEVELED_CONTENT,
  )
  const [displayingSpanishContent, toggleSpanishContent] = useToggleState(
    false,
    TOGGLE_STATE_TOOLBAR_SPANISH_CONTENT,
  )
  const [displayingTeContent, toggleTeContent] = useToggleState(
    false,
    TOGGLE_STATE_TOOLBAR_TE_CONTENT,
  )
  const [audioClosing, setAudioClosing] = useState(false)
  const audioContentId = useRef()
  const blocksHaveTe = useSelector(childBlocksHaveTeContent)
  const [presenterModeEnabled, togglePresenterMode] = useToggleState(
    false,
    TOGGLE_STATE_PRESENTER_MODE,
  )
  const teContentAvailable =
    currentUserIsTeacher &&
    blocksHaveTe &&
    !ancestorHasTe &&
    !presenterModeEnabled

  const allowAnnotations =
    !currentUserIsTeacher || (assignmentId && !userAssignmentId)

  // #538 hide spanish content for now
  const allowSpanish = has(FEATURE_FLAG_SPANISH_CONTENT)

  const blocksHaveLeveledContent = useSelector(
    hasAlternateBodiesForSelfOrDescendants({
      kind: LEVELED_TEXT_BODY_FIELD_NAME,
      contentId,
    }),
  )

  const leveledContentToggleAvailable =
    has(ABILITY_CONTENT_EDITING) ||
    (currentUserIsTeacher && blocksHaveLeveledContent)
  const useLeveledContentWhereAvailable =
    useCurrentUserShouldSeeLeveledContent() ||
    (displayingLeveledContent && leveledContentToggleAvailable)

  const spanishContentToggleAvailable =
    useSelector(
      hasAlternateBodiesForSelfOrDescendants({
        kind: SPANISH_BODY_FIELD_NAME,
        contentId,
      }),
    ) && allowSpanish
  const useSpanishContentWhereAvailable =
    displayingSpanishContent && spanishContentToggleAvailable

  const annotations = useSelector(
    getAnnotationsForContent({
      assignmentId,
      contentId,
      userId,
      displayingLeveledContent: useLeveledContentWhereAvailable,
      displayingSpanishContent: useSpanishContentWhereAvailable,
    }),
  )

  const filteredAnnotations = currentUserIsTeacher
    ? annotations
    : annotations.filter(
        ({ sharedForAssignmentId }) =>
          !!sharedForAssignmentId === studentViewingTeacherAnnotations,
      )

  const alternateBodyProp = useSpanishContentWhereAvailable
    ? 'spanishBody'
    : useLeveledContentWhereAvailable
      ? 'leveledBody'
      : undefined

  const accentColor = useSelector(
    getSubsectionOrEchoAccentColor({ contentId, sectionAccents }),
  )

  useEffect(() => {
    if (
      audioTrackEnabled &&
      audioContentId.current &&
      audioContentId.current !== contentId
    ) {
      // they clicked on a new page while playing audio
      // toggle the audio off in order to reset it
      toggleAudioTrack()
      audioContentId.current = null
    }
  }, [audioTrackEnabled, contentId, toggleAudioTrack])

  const toggleAudioOnOff = useCallback(() => {
    if (audioTrackEnabled) {
      setAudioClosing(true)
      // give the "slide down" animation time to work
      setTimeout(() => {
        toggleAudioTrack()
        setAudioClosing(false)
      }, 500)
      audioContentId.current = null
    } else {
      toggleAudioTrack()
      audioContentId.current = contentId
    }
  }, [audioTrackEnabled, contentId, toggleAudioTrack])

  const value = useMemo(() => {
    const blockBundles =
      compose(
        assembleSideBySideSeAndTeBlocks,
        markVocabPhrasesInBlocks(vocabPhrases, alternateBodyProp),
      )(blocks) || []

    const handleLeveledContentToggle = () => {
      if (!displayingLeveledContent && displayingSpanishContent) {
        toggleSpanishContent()
      }

      toggleLeveledContent()
    }

    const handleSpanishContentToggle = () => {
      if (!displayingSpanishContent && displayingLeveledContent) {
        toggleLeveledContent()
      }
      toggleSpanishContent()
    }

    return {
      allowAnnotations,
      annotations: filteredAnnotations,
      annotationsOn,
      assignmentSettings,
      audioAvailable,
      audioClosing,
      audioTrackEnabled,
      blockBundles,
      content,
      contentWrappingAllowed: !displayingTeContent && sideBySideTeAllowed,
      displayingLeveledContent: useLeveledContentWhereAvailable,
      displayingSpanishContent: useSpanishContentWhereAvailable,
      displayingTeContent: displayingTeContent && teContentAvailable,
      leveledContentToggleAvailable,
      presenterModeAvailable: currentUserIsTeacher,
      setAudioAvailable,
      setAudioClosing,
      setVideosInTheaterMode,
      sideBySideTeAllowed,
      spanishContentToggleAvailable,
      studentViewingTeacherAnnotations,
      subsection: content, // TODO: remove this
      teContentAvailable,
      toggleAnnotations,
      toggleAudioOnOff,
      toggleAudioTrack,
      toggleLeveledContent: handleLeveledContentToggle,
      togglePresenterMode,
      toggleSpanishContent: handleSpanishContentToggle,
      setStudentViewingTeacherAnnotations,
      toggleTeContent,
      videosInTheaterMode,
      ...initialTestState,
    }
  }, [
    alternateBodyProp,
    allowAnnotations,
    annotationsOn,
    assignmentSettings,
    audioAvailable,
    audioClosing,
    audioTrackEnabled,
    blocks,
    content,
    displayingLeveledContent,
    displayingSpanishContent,
    displayingTeContent,
    currentUserIsTeacher,
    filteredAnnotations,
    initialTestState,
    leveledContentToggleAvailable,
    sideBySideTeAllowed,
    spanishContentToggleAvailable,
    studentViewingTeacherAnnotations,
    teContentAvailable,
    toggleAnnotations,
    toggleAudioOnOff,
    toggleAudioTrack,
    toggleLeveledContent,
    togglePresenterMode,
    toggleSpanishContent,
    toggleTeContent,
    useLeveledContentWhereAvailable,
    useSpanishContentWhereAvailable,
    videosInTheaterMode,
    vocabPhrases,
  ])

  return retrievedInteractions || initialTestState?.retrievedInteractions ? (
    <contentViewerContext.Provider value={value}>
      <AccentColorProvider accentColor={accentColor || 'cobalt'}>
        <Alerts />

        {children}
      </AccentColorProvider>
    </contentViewerContext.Provider>
  ) : null
}

ContentViewerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  contentType: contentTypeShape,
  initialTestState: PropTypes.object,
  paramName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export { contentViewerContext, ContentViewerProvider }

import Centered from 'common/layout/Centered'
import { MaybeScoringResultIcon } from 'common/layout/ItemWithFeedback'
import ResponsiveTable from 'common/layout/ResponsiveTable'
import Html from 'common/text/Html'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { componentShape } from 'core/shapes'
import { find } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo, matches } from 'fp/utils'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { compose } from 'redux'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import ChartItem from './ChartItem'
import { findByRowColumn, itemShape, responseItemShape } from './utils'

const feedbackColumnWidth = '6rem'

const Table = props => {
  const {
    ChartItemRenderer = ChartItem,
    caption,
    captionPosition,
    columns,
    items,
    response = [],
    rows,
    ...rest
  } = props

  const { completed, scoreData } = useContext(interactiveContext)
  const rowScores = compose(fallbackTo({}), get('result.byRowId'))(scoreData)
  const columnScores = compose(fallbackTo({}), get('result.byColId'))(scoreData)
  const hasRowScores = completed && Boolean(Object.keys(rowScores).length)
  const hasColumnScores = completed && Boolean(Object.keys(columnScores).length)
  const columnStyle = { width: `${100 / columns.length}%` }

  return (
    <ResponsiveTable
      aria-label="Interactive Chart"
      sx={{
        th: {
          borderColor: 'border.0',
        },
        td: {
          height: 80,
          position: 'relative',
          '&.static': {
            borderColor: 'border.0',
            borderStyle: 'solid',
          },
          '&.draggable': {
            backgroundColor: 'tableCell',
            borderColor: 'border.0',
            padding: 0,
            transition: 'background 300ms',
            '> div': {
              margin: '0 !important',
              width: '100%',
            },
          },
          '&.hovered': {
            backgroundColor: 'cobalt.1',
            transition: 'background 300ms',
          },
        },
      }}>
      {Boolean(caption) && (
        <caption style={{ captionSide: captionPosition }}>
          <Html body={caption} />
        </caption>
      )}
      <thead>
        <tr>
          {columns.map(({ id, header }) => (
            <th
              key={id}
              style={columnStyle}>
              {header}
            </th>
          ))}
          {Boolean(hasRowScores) && (
            <th style={{ width: feedbackColumnWidth }}>
              <ScreenReaderText>Row Summary</ScreenReaderText>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map(({ id: rowId }, rowIdx) => (
          <tr key={rowId}>
            {columns.map(({ id: columnId, header }) => {
              const item = findByRowColumn(rowId, columnId)(items)
              // if it's a static item, find response by id, else by row and column for drag and drop
              const responseItem = item.isStatic
                ? find(matches('id', item.id))(response)
                : compose(
                    id => find(matches('id', id))(items),
                    get('id'),
                    findByRowColumn(rowId, columnId),
                  )(response)
              return (
                <ChartItemRenderer
                  heading={header}
                  isFirst={rowIdx === 0}
                  isLast={rows.length === rowIdx + 1}
                  item={item}
                  key={item.id}
                  responseItem={responseItem}
                  {...rest}
                />
              )
            })}
            {Boolean(hasRowScores) && (
              <td>
                <MaybeScoringResultIcon scoringResult={rowScores[rowId]} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
      {Boolean(hasColumnScores) && (
        <tfoot>
          <tr>
            {columns.map(({ id: columnId }) => (
              <td
                key={columnId}
                style={{ verticalAlign: 'middle' }}>
                <Centered>
                  <MaybeScoringResultIcon
                    scoringResult={columnScores[columnId]}
                  />
                </Centered>
              </td>
            ))}
          </tr>
        </tfoot>
      )}
    </ResponsiveTable>
  )
}

Table.propTypes = {
  caption: PropTypes.string,
  captionPosition: PropTypes.oneOf(['top', 'bottom']),
  ChartItemRenderer: componentShape,
  columns: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, header: PropTypes.string }),
  ).isRequired,
  items: PropTypes.arrayOf(itemShape).isRequired,
  response: PropTypes.arrayOf(responseItemShape),
  rows: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
}

export default Table

import BusySpinner from 'common/indicators/BusySpinner'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import useSubscribeToInteractions from 'hooks/useSubscribeToInteractions'
import { Suspense, lazy, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'
import { isStaff } from 'selectors/users'
import InteractiveContainer from './InteractiveContainer'
import InteractiveGradingProvider from './InteractiveGradingProvider'
import InteractiveProvider, { interactiveContext } from './InteractiveProvider'
import MaybeHiddenFromStudent from './MaybeHiddenFromStudent'

const TeacherInteractiveRenderer = lazy(
  () =>
    import(
      /* webpackChunkName: "TeacherInteractiveRenderer" */ './Teacher/InteractiveRenderer'
    ),
)

const InteractiveConsumer = () => {
  const { Renderer, allowedInteractives, interactive } =
    useContext(interactiveContext)

  const isCurrentUserStaff = useSelector(isStaff)

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  useSubscribeToInteractions({ interactive })

  const { contentSubType } = interactive

  if (
    Array.isArray(allowedInteractives) &&
    !allowedInteractives.includes(contentSubType)
  ) {
    return null
  }

  return (
    <InteractiveContainer>
      {isCurrentUserStaff && !presenterModeEnabled ? (
        <Suspense fallback={<BusySpinner />}>
          <TeacherInteractiveRenderer DefaultViewComponent={Renderer} />
        </Suspense>
      ) : (
        <MaybeHiddenFromStudent>
          <Renderer />
        </MaybeHiddenFromStudent>
      )}
    </InteractiveContainer>
  )
}

const Interactive = props => (
  <InteractiveGradingProvider>
    <InteractiveProvider {...props}>
      <InteractiveConsumer />
    </InteractiveProvider>
  </InteractiveGradingProvider>
)

export default Interactive

import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import UserAssignmentSelectionProvider, {
  userAssignmentSelectionContext,
} from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import PropTypes from 'prop-types'
import { Fragment, createContext, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'
import { isStaff } from 'selectors/users'

export const interactiveGradingContext = createContext()

const StaffGradingProvider = props => {
  const [scoreData, setScoreData] = useState({})
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
  const value = useMemo(
    () => ({
      saveButtonDisabled,
      setSaveButtonDisabled,
      scoreData,
      setScoreData,
    }),
    [saveButtonDisabled, scoreData],
  )

  return (
    <interactiveGradingContext.Provider
      value={value}
      {...props}
    />
  )
}

const InteractiveGradingProvider = props => {
  const { children } = props
  const currentUserIsStaff = useSelector(isStaff)
  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )
  const { currentUserAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}

  // Sometimes we're already in a student selection context,
  // e.g. the Chapter Grading component provides one for the whole page.
  const MaybeUserAssignmentSelectionProvider = useContext(
    userAssignmentSelectionContext,
  )
    ? Fragment
    : UserAssignmentSelectionProvider

  return currentUserIsStaff && !presenterModeEnabled ? (
    <MaybeUserAssignmentSelectionProvider>
      <StaffGradingProvider
        {...props}
        key={currentUserAssignmentId}
      />
    </MaybeUserAssignmentSelectionProvider>
  ) : (
    children
  )
}

InteractiveGradingProvider.propTypes = {
  children: PropTypes.node,
}

export default InteractiveGradingProvider

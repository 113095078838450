import useSubscribeToInteractions from 'hooks/useSubscribeToInteractions'
import InteractiveGradingProvider from 'hss/sections/contentBlocks/Interactive/InteractiveGradingProvider'
import InteractiveProvider from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import InteractiveRenderer from 'hss/sections/contentBlocks/Interactive/InteractiveRenderer'
import PropTypes from 'prop-types'
import useConfig from './useConfig'

export const withInteractiveProvider = (Renderer, feature, reaction = null) => {
  const Enhanced = () => {
    const interactive = useConfig({ feature })
    const { id: contentid } = interactive

    useSubscribeToInteractions({ interactive })

    return (
      <InteractiveGradingProvider>
        <InteractiveProvider
          {...{
            Renderer: InteractiveRenderer,
            contentid,
            reaction,
          }}>
          <Renderer />
        </InteractiveProvider>
      </InteractiveGradingProvider>
    )
  }

  return Enhanced
}

export const withStaticInteractiveProvider = (Renderer, feature) => {
  const Enhanced = ({ reaction, readOnly = false, reviewed = false }) => {
    const interactive = useConfig({ feature })
    const { id: contentid } = interactive

    useSubscribeToInteractions({ interactive })

    return (
      <InteractiveProvider
        {...{
          Renderer: InteractiveRenderer,
          contentid,
          children: <Renderer readOnly={readOnly} />,
          reaction,
          reviewed,
        }}
      />
    )
  }

  Enhanced.propTypes = {
    reaction: PropTypes.object,
    readOnly: PropTypes.bool,
    reviewed: PropTypes.bool,
  }

  return Enhanced
}

import Centered from 'common/layout/Centered'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import Well from 'common/layout/Well'
import { INTERACTIVE_TYPE_CHART_COMPLETION } from 'core/consts'
import { find, shuffle } from 'fp/arrays'
import { isDefined, isUndefined, matches, not, when } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import withQuestionPrompt from '../../Interactive/withQuestionPrompt'
import DraggableOptions from './DragAndDrop/DraggableOptions'
import OptionsDialog from './OptionsDialog'
import Table from './Table'
import { actions, reducer } from './utils'

const {
  CHART_COMPLETION_VOCAB_DEFINITIONS,
  CHART_COMPLETION_VOCAB_SENTENCES,
  NORMAL,
} = interactiveVariants

const ChartCompletion = withQuestionPrompt(() => {
  const {
    completed,
    interactionData: { items: response = [] },
    interactiveData: { columns, items, rows, variant = NORMAL },
    isGrading,
    onInteract,
    setSubmitEnabled,
    submittable,
  } = useContext(interactiveContext)

  /** NOTE:
   * For a static item, a response for item {id: 'GEN-xxxx'} looks like { id: 'GEN-xxxx', value: 'input text' }
   * for a drag-n-drop item, the response is defined as the dropped item and setting its row and column IDs
   * { id:'GEN-xxxx', row:'GEN-yyyy', column:'GEN-zzzz' }
   *
   * (Real IDs will be longer than the IDs in the above examples.)
   *
   */

  const [state, dispatch] = useReducer(reducer, { items: response })
  const [selectedItem, setSelectedItem] = useState()
  const [changesMade, setChangesMade] = useState(false)
  const [responseReturned, setResponseReturned] = useState(false)
  const totalOptions = useMemo(
    () => shuffle(items.filter(({ isStatic }) => not(isStatic))),
    [items],
  )
  const availableOptions = totalOptions.filter(({ id }) =>
    isUndefined(state.items.find(matches('id', id))),
  )
  const isSmallScreen = useContainerQuery().down('sm')

  const isVocabVariant = [
    CHART_COMPLETION_VOCAB_DEFINITIONS,
    CHART_COMPLETION_VOCAB_SENTENCES,
  ].includes(variant)
  const localRows = useMemo(
    () => (!isGrading && isVocabVariant ? shuffle(rows) : rows),
    [isGrading, isVocabVariant, rows],
  )
  const localDispatch = action => {
    setChangesMade(true)
    dispatch(action)
  }

  useEffect(() => {
    if (response?.length && !responseReturned) {
      setResponseReturned(true)
      dispatch({
        type: actions.SETUP,
        items: response,
      })
    }
  }, [responseReturned, response])

  useEffect(() => {
    setSubmitEnabled(
      !!state.items.filter(({ column, row, value }) => value || (column && row))
        .length,
    )
  }, [setSubmitEnabled, state.items])

  useDeepCompareEffect(() => {
    when(changesMade && !isGrading, onInteract, state)
  }, [changesMade, onInteract, state])

  return (
    <>
      {Boolean(!(completed || isSmallScreen)) && totalOptions.length > 0 && (
        <DraggableOptions
          availableOptions={availableOptions}
          dispatch={localDispatch}
        />
      )}

      {isVocabVariant && !rows.length ? (
        <Well>
          <Centered>(No vocabulary terms available)</Centered>
        </Well>
      ) : (
        <Table
          {...{
            columns,
            dispatch: localDispatch,
            items,
            rows: localRows,
            onSelectItem: setSelectedItem,
            readOnly: isGrading || !submittable,
            response: state.items,
          }}
        />
      )}

      {Boolean(!completed && isDefined(selectedItem)) && (
        <Centered>
          <OptionsDialog
            dispatch={localDispatch}
            onClose={() => {
              setSelectedItem(null)
            }}
            open
            response={state.items}
            selectedColumn={
              find(matches('id', selectedItem.column))(columns)?.header
            }
            selectedItem={selectedItem}
            totalOptions={totalOptions}
          />
        </Centered>
      )}
    </>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_CHART_COMPLETION,
}

export default ChartCompletion
